.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h1, h2, h3 {
  color: #F2B56B;
}

.bg-green {
  background-color: #238C6E;
}

.bg-orange {
  background-color: #F2B56B;
}

.text-blue {
  color: #1D6A73;
}

main > div {
  padding-top: 80px;
}

.show-top {
  z-index: 9999 !important;
}

.pointer {
  cursor: pointer;
}

.mid-size {
  width: 330px;
  padding: 1em;
}

.row-height {
  height: 100px;
  background-color: white;
}

.my-border-left {
  border-left: 1pt solid black;
}
.my-border-right {
  border-right: 1pt solid black;
}
.my-border-top {
  border-top: 1pt solid black;
}
.my-border-bottom {
  border-bottom: 1pt solid black;
}

.border-left-right:nth-child(even) {
  border-right: 1pt solid black;
  border-left: 1pt solid black;
}

.border-top-bottom:nth-child(odd) {
  border-top: 1pt solid black;
  border-bottom: 1pt solid black;
}

.cell:hover {
  background-color: #238c6e4b;
  color: #ffffff;
}

.marked {
  background-color: #238C6E;
  color: white;
}

.tab-bg {
  background-color: #1d6a7311;
}

.tab-bg.active {
  background-color: #1d6a7325 !important;
}

.alert-sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
}

.toast-fixed {
  position: fixed;
  top: 0.2em;
  left: 0px;
  right: 0px;
}

